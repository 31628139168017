/* eslint-disable no-nested-ternary */
const prod = process.env.NODE_ENV === 'production';

const baseUrl = prod ? 'https://api.eryon.ai' : 'https://api.eryon.ai';

export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: baseUrl,
  csvUrl: 'https://192.168.0.148/SampleCsv',
  sailsUrl: 'https://192.168.0.148/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'zbs5etcfyasms34yp3h0spt1h722agxsaejl5ph5ftdkiziu',
  dashboard: '/dashboard',
};
